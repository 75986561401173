<template>
  <div class="profile ma-0">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="update">
        <v-row no-gutters class="text-center">
          <v-col cols="12" class="mb-4">
            <v-hover v-slot="{ hover }">
              <v-avatar
                color="grey lighten-2"
                size="100"
                @click="uploadPicture"
              >
                <v-img :src="imageSrc" max-width="100%" max-height="100%">
                  <v-fade-transition>
                    <v-overlay :value="hover" absolute>
                      <v-row align="center" justify="center">
                        <v-col cols="12">
                          <v-btn
                            icon
                            depressed
                            width="100"
                            height="100"
                            :ripple="false"
                          >
                            <v-icon color="white" size="40">
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-avatar>
            </v-hover>

            <validation-provider
              v-slot="{ errors }"
              rules="size:5000"
              :name="$t('profile_photo')"
            >
              <input
                type="file"
                style="display: none"
                ref="uploader"
                accept="image/*"
                @change="onFilePicked"
              />

              <span class="red--text">{{ errors[0] }}</span>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <div
              class="grey lighten-3 text-heading-6 blue--text text--darken-4 font-weight-bold px-5 text-left pa-3"
            >
              基本情報
            </div>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              名前
            </div>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  name="last_name"
                >
                  <v-text-field
                    outlined
                    type="text"
                    hide-details=""
                    v-model="profile.last_name"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  name="first_name"
                >
                  <v-text-field
                    outlined
                    type="text"
                    hide-details=""
                    v-model="profile.first_name"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              名前
            </div>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  name="furigana_last_name"
                >
                  <v-text-field
                    outlined
                    type="text"
                    hide-details=""
                    v-model="profile.furigana_last_name"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50"
                  name="furigana_first_name"
                >
                  <v-text-field
                    outlined
                    type="text"
                    hide-details=""
                    v-model="profile.furigana_first_name"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              郵便番号
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="max:50"
              name="postcode"
            >
              <v-text-field
                outlined
                type="text"
                hide-details=""
                @input="changePostal"
                v-model="profile.postcode"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              都道府県
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="max:50"
              name="prefecture_id"
            >
              <v-select
                :items="allPrefectures"
                item-text="name"
                item-value="id"
                outlined
                type="text"
                hide-details=""
                v-model="profile.prefecture_id"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              市区町村
            </div>
            <validation-provider v-slot="{ errors }" rules="max:50" name="city">
              <v-text-field
                outlined
                type="text"
                hide-details=""
                v-model="profile.city"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              番地・建物名・号室
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="max:50"
              name="address"
            >
              <v-text-field
                outlined
                type="text"
                hide-details=""
                v-model="profile.address"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              生年月日
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="max:50"
              name="birth_day"
            >
              <v-text-field
                outlined
                type="text"
                hide-details=""
                v-model="profile.birth_day"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" class="px-5 text-right">
            <div @click="changePasswordActive" class="blue--text pt-4">
              パスワードを変更する
            </div>
          </v-col>

          <v-col v-if="!disabled_password" cols="12" class="px-5 text-left">
            <div class="pb-2">
              新しいパスワード
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:50|password:@confirmation_password"
              name="password"
            >
              <v-text-field
                outlined
                ref="password"
                type="password"
                hide-details=""
                v-model="profile.password"
                placeholder="新しいパスワード"
                :disabled="disabled_password"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col v-if="!disabled_password" cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              新しいパスワード（確認）
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:50"
              name="confirmation_password"
            >
              <v-text-field
                outlined
                type="password"
                hide-details=""
                data-vv-as="password"
                v-model="profile.confirmation_password"
                placeholder="新しいパスワード（確認）"
                :disabled="disabled_password"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <div
              class="grey lighten-3 text-heading-6 blue--text text--darken-4 font-weight-bold px-5 text-left pa-3 mt-5"
            >
              連絡先
            </div>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              メールアドレス
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="max:50"
              name="email"
            >
              <v-text-field
                outlined
                type="text"
                hide-details=""
                v-model="profile.email"
                placeholder="メールアドレス"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              電話番号
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="max:50"
              name="phone"
            >
              <v-text-field
                outlined
                type="text"
                hide-details=""
                v-model="profile.phone"
                placeholder="電話番号"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" class="px-5 text-left">
            <div class="pb-2 pt-3">
              緊急連絡先
            </div>
            <validation-provider
              v-slot="{ errors }"
              rules="max:50"
              name="emergency_contact"
            >
              <v-text-field
                outlined
                type="text"
                hide-details=""
                v-model="profile.emergency_contact"
                placeholder="緊急連絡先"
                :error-messages="errors"
                :error="errors.length !== 0"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" class="mt-5">
            <v-row no-gutters>
              <v-col cols="6">
                <v-btn @click="$router.push({ name: 'profile' })" text block>
                  キャンセル
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  type="submit"
                  large
                  :disabled="invalid"
                  color="primary"
                  block
                >
                  保存する
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { extend } from "vee-validate";

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});

export default {
  name: "EditProfilePage",
  computed: {
    ...mapGetters(["profile", "allPrefectures"]),
    imageSrc: {
      get() {
        if (this.preview) return this.preview;

        return this.profile.profile_image
          ? this.profile.profile_image_url
          : `${process.env.VUE_APP_ROOT_API}images/userblank.png`;
      },
      set(value) {
        this.preview = value;
      }
    },
    imageDimensions() {
      if (this.profile.photo) {
        let photoUrl = this.profile.photo;
        let photoFilename = photoUrl.substring(photoUrl.lastIndexOf("/") + 1);
        if (photoFilename == "avatar.svg") {
          return { width: "60px", height: "60px" };
        } else {
          return { width: "100%", height: "100%" };
        }
      } else {
        return { width: "0", height: "0" };
      }
    }
  },
  data() {
    return {
      files: [],
      password: null,
      new_password: null,
      disabled_password: true,
      preview: null,
      profile_image: null
    };
  },
  created() {
    this.loading = true;
    this.$store.dispatch("PREFECTURE_GET_ALL");
    this.$store.dispatch("PROFILE_GET").finally(() => (this.loading = false));
  },
  methods: {
    uploadPicture() {
      this.$refs.uploader.click();
    },
    onFilePicked(e) {
      this.profile_image = e.target.files[0];
      this.imageSrc = URL.createObjectURL(this.profile_image);
    },
    changePostal() {
      var postal_code = require("japan-postal-code");
      this.resetPrefectureCity();

      postal_code.get(this.profile.postcode, address => {
        let selected_prefecture = this.allPrefectures.filter(
          prefecture => address.prefecture === prefecture.name
        )[0];

        this.profile.prefecture = selected_prefecture.name;
        this.profile.prefecture_id = selected_prefecture.id;
        this.profile.city = address.city;
      });
    },
    resetPrefectureCity() {
      this.profile.prefecture = null;
      this.profile.prefecture_id = null;
      this.profile.city = null;
    },
    changePasswordActive() {
      this.disabled_password = !this.disabled_password;
    },
    update() {
      this.$refs.observer.validate();

      this.loading = true;

      let formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('profile_image' , this.profile_image)
      for(const[key,value] of Object.entries(this.profile)){
        if(key !== 'profile_image'){
          formData.append(key , value ? value : value === 0 ? 0 : '')
        }
      }
      this.$store.dispatch("EDIT_PROFILE", {id: this.profile.id, data: formData}).then(result => {
        this.loading = false;
        if (result.status === 200) {
          this.$refs.observer.reset();
          this.$router.push({ name: "profile" });
        }
      });
    }
  }
};
</script>

<style scoped>
.profile {
  max-width: 100% !important;
}
</style>
