<template>
  <EditProfilePage />
</template>

<script>
import EditProfilePage from "@/components/layout/user/EditProfilePage";
export default {
  name: "EditProfile",
  components: { EditProfilePage }
};
</script>

<style scoped></style>
